<template>
  <div class="table-content">
    <div class="table-title">
      <div>广告列表</div>
    </div>

    <!-- 列表 -->
    <el-table
      :data="tableData"
      border
      tooltip-effect="dark"
      style="width: 100%"
      v-loading="loading"
      element-loading-text="加载中"
      element-loading-spinner="el-icon-loading"
    >
      <el-table-column
        prop="bg_app"
        label="所属产品"
        width="250"
        align="center"
      >
      </el-table-column>
      <el-table-column prop="title" label="广告名称" width="150" align="center">
      </el-table-column>
      <el-table-column
        prop="bg_position"
        label="位置"
        width="180"
        align="center"
      >
      </el-table-column>
      <!-- <el-table-column label="位置" width="180" align="center">
        <template #default="scope">
          <el-image style="width: 150px" :src="scope.row.picture"></el-image>
        </template>
      </el-table-column> -->
      <el-table-column
        prop="status"
        label="广告状态"
        width="150"
        align="center"
      >
      </el-table-column>
      <el-table-column
        fixed="right"
        label="操作"
        show-overflow-tooltip
        min-width="200"
        align="center"
      >
        <template #default="scope">
          <el-button
            v-if="type.indexOf('编辑') > -1"
            @click="handleClick(scope.row.id, scope.$index)"
            type="text"
            size="small"
            >编辑</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <!-- 列表 -->

    <!-- 编辑弹窗 -->
    <el-dialog title="编辑广告" v-model="dialogFormVisible" width="500px">
      <el-form :model="form" :rules="rules" label-width="100px">
        <el-form-item label="所属应用：">
          <div>{{ title }}</div>
        </el-form-item>
        <el-form-item label="广告名称：" prop="title">
          <el-input v-model="form.title"></el-input>
        </el-form-item>
        <el-form-item label="所属位置：">
          <div>{{ position }}</div>
        </el-form-item>
        <el-form-item label="广告图：">
          <div class="follow-update">
            <div v-if="form.picture" style="margin-right: 20px">
              <el-image
                style="width: 100px"
                :src="form.picture"
                fit="contain"
              ></el-image>
            </div>
            <div class="update-group">
              <el-button type="primary" size="small" round>上传</el-button>
              <input
                name="audio"
                class="update-btn"
                type="file"
                accept="image/png,image/jpg,image/jpeg,image/gif"
                @change="handUpFile"
              />
              <el-input v-model="form.picture" class="follow-audio"></el-input>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="指向类型：">
          <div>链接</div>
        </el-form-item>
        <el-form-item label="链接地址：" prop="link_address">
          <el-input v-model="form.link_address"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="onSubmit">确 定</el-button>
          <el-button @click="dialogFormVisible = false">取 消</el-button>
        </span>
      </template>
    </el-dialog>
    <!-- 编辑弹窗 -->
  </div>
</template>

<script>
import {
  advertisementList,
  followreadUploadtonas,
  advertisementEdit,
} from "@/api/apiList/operate-api";

export default {
  data() {
    return {
      tableData: [],
      loading: true,
      type: "",
      title: "Uummii APP",
      position: "首页广告",
      dialogFormVisible: false,
      form: {
        id: "",
        title: "",
        picture: "",
        link_address: "",
      },
    };
  },
  methods: {
    // 编辑弹窗
    handleClick(e, idx) {
      this.form.id = e;
      this.title = this.tableData[idx].bg_app;
      this.position = this.tableData[idx].bg_position;
      this.dialogFormVisible = true;
    },
    // 图片上传
    handUpFile(e) {
      let obj = {
        file: e.target.files[0],
      };
      let formData = new FormData();
      for (let key in obj) {
        formData.append(key, obj[key]);
      }
      followreadUploadtonas(formData).then((res) => {
        if (res.result === "200") {
          this.$message.success({
            message: res.msg,
            type: "success",
          });
          this.form.picture = res.data;
        }
      });
    },
    // 编辑提交
    onSubmit() {
      advertisementEdit(this.form).then((res) => {
        if (res.result === "200") {
          this.$message.success({
            message: res.msg,
            type: "success",
          });
          this.dialogFormVisible = false;
          this.handList();
        }
      });
    },
    handList() {
      advertisementList().then((res) => {
        if (res.result === "200") {
          res.data.forEach((item) => {
            switch (item.bg_app) {
              case 1:
                item.bg_app = "Uummii App";
                break;
              case 2:
                item.bg_app = "Uummii 纠音";
                break;
            }
            switch (item.bg_position) {
              case 1:
                item.bg_position = "首页广告";
                break;
              case 2:
                item.bg_position = "个人中心";
                break;
            }
            switch (item.status) {
              case 1:
                item.status = "上架";
                break;
              case 2:
                item.status = "下架";
                break;
            }
          });
          this.tableData = res.data;
          this.loading = false;
        }
      });
    },
  },
  mounted() {
    this.type = this.$store.state.type;
    this.handList();
  },
};
</script>

<style lang="scss" scoped>
.follow-update {
  display: flex;
  align-items: center;

  .update-group {
    position: relative;

    .update-btn {
      position: absolute;
      width: 56px;
      height: 32px;
      top: 4px;
      left: 0;
      z-index: 1;
      opacity: 0;
    }

    .follow-audio {
      position: absolute;
      width: 56px;
      height: 32px;
      top: 4px;
      left: 0;
      z-index: -1;
      opacity: 0;
    }
  }
}
</style>
